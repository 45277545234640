<template>
	<div class="Ov404">

		<OvMainHeadline
			:text="'Error 404<br/>Page not found'"
		></OvMainHeadline>

		<OvSpacer type="default" :rows="6"></OvSpacer>

		<OvText
			:text="'Oops, this page was not found.<br/>Go to <a href=/en/>Homepage</a>.'"
		></OvText>
		<br>
		<OvText
			:text="'Hoppla, diese Seite wurde leider nicht gefunden.<br/>Hier geht es zur <a href=/de/>Startseite</a>.'"
		></OvText>

		<!--
		<template v-if="app.lang == 'de'">
			<OvText
				:text="'Hoppla, diese Seite wurde leider nicht gefunden.<br/>Hier geht es zur <a href=/de/>Startseite</a>.'"
			></OvText>
		</template>
		<template v-else>
			<OvText
				:text="'Oops, this page was not found.<br/>This way it goes to the <a href=/en/>Homepage</a>.'"
			></OvText>
		</template>
		-->

		<!--
		<pre name="app.lang">{{app.lang}}</pre>
		-->

	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'

	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import OvText from '@/components/OvText.vue'

	export default {
		name: 'Ov404',
		components: {
			OvMainHeadline,
			OvSpacer,
			OvText,
		},
		mixins: [],
		props: {},
		data() {
			return {
				isContentReady : true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted(){}
	}
</script>

<style lang="less" scoped>
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.Ov404 {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
