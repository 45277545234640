<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvContentModules></OvContentModules>

	2020-11-23	init

-->

<template>
	<div class="OvContentModules" :class="elmClasses">
		<!-- contentModules -->
		<div v-for="(cm, index) in contentModules"
			:key="index" :is="getComponentName(cm)" :postType="post.type"
			v-bind="getComponentBind(cm)">
			<pre data-name="cm">{{getComponentName(cm)}}</pre>
			<pre data-name="cm">{{cm}}</pre>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	import OvHeadline from '@/components/OvHeadline.vue'
	import OvText from '@/components/OvText.vue'
	import OvAccordion from '@/components/OvAccordion.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import OvImage from '@/components/OvImage.vue'
	import OvImages from '@/components/OvImages.vue'
	import OvHtmlEmbed from '@/components/OvHtmlEmbed.vue'
	import OvHtmlEmbeds from '@/components/OvHtmlEmbeds.vue'
	import OvImageAndText from '@/components/OvImageAndText.vue'
	import OvEventsList from '@/components/OvEventsList.vue'
	import OvLogoGrid from '@/components/OvLogoGrid.vue'
	import OvQuote from '@/components/OvQuote.vue'

	export default {
		name: 'OvContentModules',
		components: {
			OvHeadline,
			OvText,
			OvAccordion,
			OvSpacer,
			OvImage,
			OvImages,
			OvHtmlEmbed,
			OvHtmlEmbeds,
			OvImageAndText,
			OvEventsList,
			OvLogoGrid,
			OvQuote,
		},
		mixins: [],
		props: {
			post: [Object, Boolean],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			contentModules(){
				const currentLang          = this.$i18n.locale
				const inputContentModules  = this._.get( this.post, 'acf.contentModules__' + currentLang + '.contentModules' )
				const outputContentModules = []
				const mediumSpacer         = { acf_fc_layout : "spacer", type : "medium" }

				//console.log('inputContentModules:', inputContentModules)

				this._.forEach( inputContentModules, (m, i)=>{
					const next          = inputContentModules[i+1]
					const nextLayout    = next ? next.acf_fc_layout : false
					const currentLayout = m ? m.acf_fc_layout : false

					// jeder headline wird ein mediumSpacer vorangestellt
					if( currentLayout == 'headline' && i > 0 ) outputContentModules.push(mediumSpacer)
					// add the contentModule
					outputContentModules.push(m)
					// nach jedem module kommt ein mediumSpacer dazu
					// außer bei OvImageAndText
					if( currentLayout === 'imageAndText' && nextLayout == 'imageAndText'){
						//outputContentModules.push(mediumSpacer)
					}else{
						outputContentModules.push(mediumSpacer)
					}
				})

				//console.log('outputContentModules:', outputContentModules)

				return outputContentModules
			},
		},
		methods: {
			getComponentName( obj ){
				return 'Ov' + this._.upperFirst( obj['acf_fc_layout'] )
			},
			getComponentBind( obj ){
				//delete obj['acf_fc_layout']

				return obj
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvContentModules {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
