<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvHtmlEmbed></OvHtmlEmbed>

	2020-11-23	init

-->

<template>
	<div class="OvHtmlEmbed contentModule" :class="elmClasses">

		<div class="OvHtmlEmbed__inner contentModule__inner maxWidth maxWidth--app hSpace hSpace--app">
			<div class="OvHtmlEmbed__htmlEmbed">
				<!--
				<MhIframe :htmlCode="htmlCode"></MhIframe>
				-->
				<MhConsentEmbedWrapper :htmlCode="htmlCode"></MhConsentEmbedWrapper>
				<div class="OvHtmlEmbed__textWrapper" v-if="text">
					<OvSpacer></OvSpacer>
					<div class="richText breakWord lineCrop font font--sizeSmall" v-html="text"></div>
					<OvSpacer></OvSpacer>
				</div>
			</div>
		</div>
		<!--
		<pre name="htmlCode">{{htmlCode}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhConsentEmbedWrapper from '@/components/MhConsent/MhConsentEmbedWrapper.vue'
	import OvSpacer from '@/components/OvSpacer.vue'

	export default {
		name: 'OvHtmlEmbed',
		components: {
			MhConsentEmbedWrapper,
			OvSpacer,
		},
		mixins: [],
		props: {
			htmlCode: {
				type: [String, Boolean],
				default: '',
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed() {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvHtmlEmbed { // layout
		&__inner {
			.grid;
			.grid--setAreas;
			.grid--colGap;

			@media @mq[dt] {
				.grid--cols-12;
				grid-template-areas: ". . a a a a a a a a . .";
			}
			@media @mq[md] {
				.grid--cols-12;
				grid-template-areas: "a a a a a a a a a a a a";
			}
			@media @mq[sm] {
				.grid--cols-4;
				grid-template-areas: "a a a a";
			}

		}
		&__textWrapper { // handles text indent
			//background-color: fade( red, 20 );

			.grid;
			.grid--setAreas;
			.grid--colGap;

			// a	spacer above
			// b	text
			// c	spacer below

			@media @mq[dt] {
				.grid--cols-8;
				grid-template-areas: "a a a a a a a a"
									 "b b b b b b b b"
									 "c c c c c c c c";
			}
			@media @mq[md] {
				.grid--cols-8;
				grid-template-areas: "a a a a a a a a"
									 "b b b b b b b b"
									 "c c c c c c c c";
			}
		}
	}
	.OvHtmlEmbed { // styling

	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
