<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvImage></OvImage>

	2020-11-23	init

-->

<template>
	<div class="OvImage contentModule" :class="elmClasses" v-if="image">
		<div class="OvImage__inner contentModule__inner " :class="elmInnerClasses">
			<div class="OvImage__imageWrapper" :class="imageWrapperClasses">
				<MhImage class="OvImage__image" :imageObject="image"></MhImage>
				<OvSpacer v-if="isPortrait"></OvSpacer>
			</div>
			<div class="OvImage__textWrapper maxWidth maxWidth--app" :class="textWrapperClasses">
				<OvSpacer v-if="!isPortrait && text"></OvSpacer>
				<div class="OvImage__text richText breakWord lineCrop font font--sizeSmall"><div v-html="text"></div></div>
				<OvSpacer></OvSpacer>
			</div>
		</div>
		<!--
		<pre name="textPosition">{{textPosition}}</pre>
		<pre name="text">{{text}}</pre>
		<pre name="image">{{image}}</pre>
		<pre name="isPortrait">{{isPortrait}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import OvSpacer from '@/components/OvSpacer.vue'

	export default {
		name: 'OvImage',
		components: {
			MhImage,
			OvSpacer,
		},
		mixins: [],
		props: {
			image: {
				type: [Object, Boolean],
				default: false,
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
			textPosition: {
				type: [String, Boolean],
				default: 'left',
			},
			fullspan: {
				type: [Boolean],
				default: false,
			},
		},
		data() {
			return {
				isPortrait : undefined,
			}
		},
		watch: {
			image: {
				handler: function( to, from ) {
					const image      = to
					const width      = this._.get( image, 'width')
					const height     = this._.get( image, 'height')
					const isPortrait = (width && height) ? ((height > width) ? true : false) : undefined

					/*
					console.group('OvImage • watch.image')
					console.log('      from:', from)
					console.log('        to:', to)
					console.log('     width:', width)
					console.log('    height:', height)
					console.log('isPortrait:', isPortrait)
					console.groupEnd()
					*/

					this.isPortrait = isPortrait
				},
				immediate: true,
				deep: false,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isPortrait === false && this.fullspan === true )    classes.push('OvImage--isFullspan')
				if( this.isPortrait === true )  classes.push('OvImage--isPortrait')
				if( this.isPortrait === false ) classes.push('OvImage--isLandscape')
				if( this.isPortrait === true ) classes.push('OvImage--textPosition' + this._.upperFirst( this.textPosition ) )

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.isPortrait === true ){
					classes.push('maxWidth maxWidth--app hSpace hSpace--app')
				}

				return classes
			},
			imageWrapperClasses(){
				let classes = []

				if( this.isLandscape && this.fullspan ){
					classes.push('maxWidth maxWidth--fullspan')
				}
				if( this.isLandscape && !this.fullspan ){
					classes.push('maxWidth maxWidth--app hSpace hSpace--app')
				}

				return classes
			},
			textWrapperClasses(){
				let classes = []

				if( this.isLandscape ){
					classes.push('hSpace hSpace--app')
				}

				return classes
			},
			isLandscape(){
				return !this.isPortrait
			}
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvImage { // grid layout
		&--isLandscape {
			.OvImage__imageWrapper {
				margin: 0 auto;
			}
			.OvImage__textWrapper {
				margin: 0 auto;
			}
			.OvImage__textWrapper {
				.grid;
				.grid--setAreas;
				.grid--colGap;

				@media @mq[dt] { .grid--cols-12; }
				@media @mq[md] { .grid--cols-8; }
				@media @mq[sm] { .grid--cols-8;  }

				grid-template-areas: ". . a a a a a a a a . ."
									 ". . b b b b b b b b . ."
									 ". . c c c c c c c c . .";

				@media @mq[md] {
					grid-template-areas: "a a a a a a a a"
										 ". . b b b b b b"
										 ". . c c c c c c";
				}
			}
		}
		&--isLandscape&--isFullspan {
			.OvImage__textWrapper {
				grid-template-areas: ". . a a a a a a a a . ."
									 ". . b b b b b b b b . ."
									 ". . c c c c c c c c . .";

				@media @mq[md] {
					grid-template-areas: "a a a a a a a a"
										 ". . b b b b b b"
										 ". . c c c c c c";
				}
			}
		}

		&--isPortrait {
			.OvImage__inner {
				.grid;
				.grid--setAreas;
				.grid--colGap;

				@media @mq[dt] { .grid--cols-12; }
				@media @mq[md] { .grid--cols-8; }
				@media @mq[sm] { .grid--cols-8;  }

				.OvImage--textPositionTextLeft & {
										 //". . c c c c c c c c . .";
				}
				.OvImage--textPositionTextRight & {
					grid-template-areas: ". . a a a a a a b b . .";
										 //". . c c c c c c c c . .";
				}
			}
		}
		&--isPortrait&--textPositionTextLeft .OvImage__inner {
			grid-template-areas: ". . b b a a a a a a . .";

			@media @mq[md] {
				grid-template-areas: "b b b a a a a a";
			}
		}
		&--isPortrait&--textPositionTextRight .OvImage__inner {
			grid-template-areas: ". . a a a a a a b b . .";

			@media @mq[md] {
				grid-template-areas: "a a a a a b b b";
			}
		}
	}
	.OvImage { // styling
		&__textWrapper {
			//display: flex;
			//flex-direction: column;
		}
		&__text {
			.OvImage--isPortrait.OvImage--textPositionTextLeft & {
				display: flex;
				align-items: flex-end;
				flex-grow: 1;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
