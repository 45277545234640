<!--

	Diese Componente fügt ein Consent-Overlay über dem Iframe ein
	und lädt diesen nur, wenn der User sein "Allow" gegeben hat.

	Welche $props gibt es?
		htmlCode = html string, e.g. '<iframe .... ></iframe>'

	Beispiel Code:
		<MhConsentEmbedWrapper
			:htmlCode="'<iframe src="https://domain.com"></iframe>'"

			:titleClass="''"
			:textClass="''"
			:buttonClass="''"
			:disclaimerClass="''"
		></MhConsentEmbedWrapper>

	2021-02-18	init

-->

<template>
	<div class="MhConsent MhConsentEmbedWrapper" :class="elmClasses" v-if="htmlCode">
		<MhEmbed
			:htmlCode="htmlCode"
			:doLoadIframe="canLoadIframe"
		></MhEmbed>

		<div class="MhConsent__card MhConsentEmbedWrapper__overlay" :hostname="iFrameHostname">
			<h3 class="MhConsentEmbedWrapper__overlayTitle" :class="titleClass">
				Empfohlener Inhalt
			</h3>

			<p class="MhConsentEmbedWrapper__overlayText" :class="textClass">
				An dieser Stelle finden Sie zusätzliche Inhalte von »{{iFrameHostname}}«.
			</p>

			<div class="MhConsentEmbedWrapper__overlayExpander"></div>

			<div class="MhConsentEmbedWrapper__overlayButtonWrapper">
				<button class="MhConsent__button MhConsent__button--cta MhConsentEmbedWrapper__overlayButton" :class="buttonClass" @click="doAllow()">
					Externen Inhalt laden
				</button>
				<label class="MhConsentEmbedWrapper__overlayRemberChoice" :class="disclaimerClass">
					<input type="checkbox" v-model="isAllowedAlways" />
					<span>Für diesen Anbieter immer entsperren.</span>
				</label>
			</div>

			<div class="MhConsentEmbedWrapper__overlayExpander"></div>

			<p class="MhConsentEmbedWrapper__overlayDisclaimer" :class="disclaimerClass">
				Ich bin damit einverstanden, dass mir externe Inhalte angezeigt werden. Mit meiner Zustimmung können personenbezogene Daten an Drittplattformen übermittelt werden. Weitere Informationen und den Widerruf der Zustimmung finden Sie in unserer
				<a href="">Datenschutzerklärung</a>.
			</p>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import MhEmbed from '@/components/MhEmbed/MhEmbed.vue'

	export default {
		name: 'MhConsentEmbedWrapper',
		components: {
			MhEmbed,
		},
		mixins: [],
		props: {
			htmlCode: {
				type: [String, Boolean],
				default: '',
			},
			titleClass: {
				type: [String, Boolean],
				default: 'titleClass',
			},
			textClass: {
				type: [String, Boolean],
				default: 'textClass',
			},
			buttonClass: {
				type: [String, Boolean],
				default: 'buttonClass',
			},
			disclaimerClass: {
				type: [String, Boolean],
				default: 'disclaimerClass',
			},
		},
		data(){
			return {
				isAllowed : false,
				isAllowedAlways : true,
				mario : true,
			}
		},
		watch: {
			watch: {},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isAllowed ){
					classes.push( this.$options.name + '--isAllowed')
				}else{
					classes.push( this.$options.name + '--isNotAllowed')
				}

				return classes
			},
			canLoadIframe(){
				return this.isAllowed
			},
			iFrameHostname(){
				const div = document.createElement('div')
				div.innerHTML = this.htmlCode //.trim()

				let elm      = div.querySelector('iframe')
				let src      = elm ? elm.getAttribute('src') : false
				let domain   = src ? (new URL(src)) : false
				let hostname = domain ? domain.hostname : false

				return hostname
			},
			consentStore(){
				let doLog = false
				let currentState = JSON.parse( localStorage.getItem('MhConsent') )
				currentState = currentState ? currentState : {}

				// group groupCollapsed
				if( doLog ){
					console.group( this.$options.name + ' • consentStore')
					console.log('currentState:', currentState)
					console.groupEnd()
				}

				return currentState
			},
		},
		methods: {
			doAllow( doLog = false ){
				const hostname = this.iFrameHostname
				if( doLog ){
					console.group( this.$options.name + ' • doAllow()')
					console.log('hostname:', hostname)
					console.groupEnd()
				}

				/*
				// allow wird via EventBus emitted damit er von allen
				// anderen MhConsentEmbedWrapper empfangen werden kann.
				// Wenn diese auf die gleichen hostname verweisen werden
				// sie ebenfalls aktiviert.
				if( this.isAllowedAlways ){
					EventBus.$emit( this.$options.name + '--isAllowedAlways', hostname )
				}
				// wenn die einstellung nicht gespeichert werden soll
				// wird einfach nur dieser iframe geladen und alle anderen
				// bleiben gesperrt.
				else{
					this.isAllowed = true
				}
				*/

				this.isAllowed = true
				EventBus.$emit( this.$options.name + '--isAllowedAlways', hostname )

				// bei always allow wird der hostname des iframes im localStorage
				// zusammen mit dem aktuellem timestamp gespeichert
				if( this.isAllowedAlways ){
					let currentState = this.consentStore

					currentState[hostname] = Math.floor(Date.now() / 1000)

					localStorage.setItem('MhConsent', JSON.stringify(currentState))
				}
			},
		},
		created(){
			// Anhand dieses Events wird der iFrame freigeschaltet sofern
			// seine iFrame src-url die gleiche ist.
			EventBus.$on( this.$options.name + '--isAllowedAlways', (hostname, doLog = false)=>{
				if( doLog ){
					console.groupCollapsed('EventBus • $on', this.$options.name + '--doAllow')
					console.log( 'hostname:', hostname )
					console.groupEnd()
				}

				if( hostname === this.iFrameHostname ) this.isAllowed = true
			})
		},
		mounted( doLog = false ){
			// read stored consent settings
			const currentState = this.consentStore

			// wenn für den hostname des iFrames zuvor ein allow erteilt
			// wurde, wird der iFrame gleich geladen
			if( currentState[this.iFrameHostname] ){
				this.isAllowed = true
			}

			// group groupCollapsed
			if( doLog ){
				console.group( this.$options.name + ' • mounted()')
				console.log('htmlCode:', this.htmlCode)
				console.log('currentState:', currentState)
				console.log('this.$route:', this.$route)
				console.groupEnd()
			}
		},
		beforeDestroy(){
			// Remove listener
			EventBus.$off( this.$options.name + '--isAllowedAlways' )
		},
	}
</script>

<style lang="less">
</style>
