<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvImageAndText></OvImageAndText>

	2020-12-30	init

-->

<template>
	<div class="OvImageAndText contentModule background2 background2--color-primary-backgroundDarker" :class="elmClasses">
		<div class="OvImageAndText__line OvImageAndText__line--above background background--primary-55"></div>
		<div class="OvImageAndText__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app">
			<div class="OvImageAndText__content">
				<div class="OvImageAndText__imageWrapper">
					<OvSpacer v-if="image"></OvSpacer>
					<MhImage class="shadow shadow--default" :imageObject="image"></MhImage>
					<OvSpacer v-if="image"></OvSpacer>
				</div>
				<div class="OvImageAndText__textsWrapper">
					<OvSpacer></OvSpacer>
					<h4 class="OvImageAndText__title lineCrop font font--sizeMedium font--bold" v-html="title"></h4>
					<OvSpacer></OvSpacer>
					<div class="OvImageAndText__text font font--sizeDefault richText" v-html="text"></div>
					<div class="OvImageAndText__textSmall font font--sizeSmall richText" v-html="textSmall"></div>
					<OvSpacer :rows="2"></OvSpacer>
				</div>
			</div>
		</div>
		<div class="OvImageAndText__line OvImageAndText__line--below background background--primary-55"></div>
		<!--
		<pre name="image">{{image}}</pre>
		<pre name="title">{{title}}</pre>
		<pre name="text">{{text}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import OvSpacer from '@/components/OvSpacer.vue'

	export default {
		name: 'OvImageAndText',
		components: {
			MhImage,
			OvSpacer,
		},
		mixins: [],
		props: {
			image: {
				type: [Object, Boolean],
				default: false,
			},
			title: {
				type: [String, Boolean],
				default: false,
			},
			text: {
				type: [String, Boolean],
				default: false,
			},
			textSmall: {
				type: [String, Boolean],
				default: false,
			},
			/*
			*/
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			handleScroll(){
				console.log('scrolled')
			}
		},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvImageAndText { // debug
		//background-color: fade( red, 20 );

		&__imageWrapper {
			//background-color: fade( red, 20 );
		}
		&__textsWrapper {
			//background-color: fade( green, 20 );
		}
	}
	.OvImageAndText { // layout
		//background-color: fade( red, 20 );

		&__content {
			.grid;
			.grid--setAreas;
			.grid--colGap;

			@media @mq[dt] { .grid--cols-12; }
			@media @mq[md] { .grid--cols-8; }
			//@media @mq[sm] { .grid--cols-8; }

			grid-template-areas: "a a a a a b b b b b b b"
								 "a a a a a c c c c c c c";

			@media @mq[md] {
				grid-template-areas: "a a a a a a a a"
									 "b b b b b b b b"
									 "c c c c c c c c";
			}
		}
	}
	.OvImageAndText { // styling
		//background-color: fade( red, 20 );

		margin-bottom: -1px; // damit sich die lines nicht doppeln wenn mehrere in einem stapel stehen

		border-top: 1px solid var(--color-primary-backgroundLine);
		border-bottom: 1px solid var(--color-primary-backgroundLine);

		border-top: 1px solid var(--color-primary-highlightDarker);
		border-bottom: 1px solid var(--color-primary-highlightDarker);

		&__inner {}
		&__line {
			position: absolute;
			left: 0; right: 0;
			height: 1px;

			&--above { top: 0; }
			&--below { bottom: 0; }
		}
		&__imageWrapper {
			padding-right: 1em;

			@media @mq[md] {
				padding-right: 0em;
				//padding-top: 0.5em;
			}

			.mhImage {
				margin-top: 0.5em;
				margin-bottom: 1em;
			}
		}
		&__textsWrapper {
			//opacity: 0.7;
		}
		&__title {
			padding-top: 0.85em;

			@media @mq[md] {
				padding-top: 0.5em;
			}
		}
		&__text {
			padding-bottom: 1.3em;
		}
		&__title,
		&__text,
		&__textSmall {
			&:empty { display: none; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
