<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<Accordion>
			<AccordionItem
				title="First AccordionItem Title"
			></AccordionItem>
			<AccordionItem
				title="Second AccordionItem Title"
			></AccordionItem>
		</Accordion>
	
	2020-11-24	improved: remove useless styling
	2020-06-08	init

-->

<template>
	<div class="Accordion" :class="elmClasses" @registerItem="registerItem">
		<slot></slot>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'Accordion',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {
				items : []
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				//classes.push('new_class')
				
				return classes
			},
		},
		methods: {
			registerItem( item, doLog = false ){
				if( doLog ){
					console.group('registerItem()')
					console.log('item:', item)
					console.groupEnd()
				}
				
				this.items.push( item )
			},
			toggleItem( item, doLog = true ){
				const isActive  = item.isActive
				const itemIndex = this._.indexOf( this.items, item ) 
				
				if( doLog ){
					console.group('toggleItem( item )')
					console.log('     item:', item)
					console.log(' isActive:', isActive)
					console.log('itemIndex:', itemIndex)
					console.groupEnd()
				}
				
				if( !isActive ){
					this._.forEach( this.items, (item)=>{						
						item.setActive( false )
					})
					item.setActive( true )
				}
				if( isActive ){
					item.setActive( false )
					//this.items[itemIndex-1].setActive( true )
				}
				
			}
		},
		created() {},
		mounted() {
			// activate first item
			//this.items[0].setActive( true )
		},
	}
</script>

<style lang="less">
	.Accordion {
		//background-color: fade( red, 20 );
		
		display: flex;
		flex-direction: column;
		flex-grow: 1; // let it grow in parent
		min-height: 1em; // just to make it visible
	}
</style>
