<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="OvQuote contentModule" :class="elmClasses">
		<div class="OvQuote__inner contentModule__inner layout layout--□□▨▨▨▨▨▨▨▨□□ maxWidth maxWidth--app hSpace hSpace--app" :class="elmInnerClasses">
			<div >
				<div class="OvQuote__text font richText richText--noHyphens XXXlineCrop font--bold" :class="['font--size' + _.upperFirst(this.fontSize)]" v-html="text"></div>
				<div class="OvQuote__author font font--sizeSmall" v-html="author"></div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'OvQuote',
		components: {},
		mixins: [],
		props: {
			postType: {
				type: [String, Boolean],
				default: '',
			},
			text: {
				type: [String, Boolean],
				default: '',
			},
			author: {
				type: [String, Boolean],
				default: false,
			},
			fontSize: {
				type: [String, Boolean],
				default: 'medium',
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				if( this.fontSize == 'small' ){
					classes.push('font--sans')
				}else {
					classes.push('font--serif')
				}
				if( this.postType == 'page' ){
					//classes.push('hSpace hSpace--app')
					classes.push('font--size' + this._.upperFirst(this.fontSize))
				}else{
					classes.push('font--sizeSmall')
				}

				classes.push('font--color' + this._.upperFirst(this.color))
				classes.push('layout--' + this.layout)

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvQuote {
		&__text {
			//background-color: fade( red, 20 );
		}
		&__author {
			//background-color: fade( blue, 20 );
			padding-top: 0.5em;
			//text-align: right;
			&::before { content: "— " }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
