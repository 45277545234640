<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvImages></OvImages>

	2020-11-23	init

-->

<template>
	<div class="OvImages contentModule" :class="elmClasses">
		<div class="OvImages__inner">
			<VueperSlides
				class="vueperSlides contentModule__inner maxWidth maxWidth--fullspan"
				ref="imagesSlider"
				:infinite="false"
				:disableArrowsOnEdges="true"
				:transitionSpeed="250"
				:touchable="true"
				:slideRatio="slideRatio"
				:draggingDistance="70"
				:arrowsOutside="false"
				:bullets="false"
				:bullets-outside="true"
				@ready="onSliderReady"
				@slide="onSliderSlide"
				v-if="images"
			>
				<VueperSlide v-for="(item, i) in images" :key="i">
					<div slot="slideContent">
						<MhImage
							class="OvImages__image"
							mode="cover"
							:imageObject="item.image"
						></MhImage>
					</div>
				</VueperSlide>
				<template v-slot:arrowLeft><OvLabel2 class="font--sizeMedium" bgColor="white" :showArrowAfter="true"></OvLabel2></template>
				<template v-slot:arrowRight><OvLabel2 class="font--sizeMedium" bgColor="white" :showArrowAfter="true"></OvLabel2></template>
			</VueperSlides>

			<OvSpacer></OvSpacer>

			<div class="OvImages__texts contentModule__inner hSpace hSpace--app maxWidth maxWidth--app" v-if="images">
				<div class="OvImages__index lineCrop font font--bold font--sizeMedium">{{currentIndex+1}} <span class="OvImages__indexSlash"></span> {{images.length}}</div>
				<div class="OvImages__text richText breakWord lineCrop font font--sizeSmall" v-html="currentText"></div>
			</div>

			<OvSpacer></OvSpacer>
		</div>
		<!--
			<button @click="$refs.imagesSlider.next()">Next</button>
			<pre>{{images}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import OvLabel2 from '@/components/OvLabel2.vue'
	import VueperSlides from '@/components/VueperSlides/VueperSlides.vue'
	import VueperSlide from '@/components/VueperSlides/VueperSlide.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import OvSpacer from '@/components/OvSpacer.vue'

	export default {
		name: 'OvImages',
		components: {
			OvLabel2,
			VueperSlides,
			VueperSlide,
			MhImage,
			OvSpacer,
		},
		mixins: [],
		props: {
			images: {
				type: [Array, Boolean],
				default: false,
			},
		},
		data() {
			return {
				currentIndex : 0
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			slideRatio(){
				const images     = this.images
				const firstImage = images ? images[0].image : undefined
				const ratio      = firstImage ? firstImage.height / firstImage.width : undefined
				const doLog      = false

				if( doLog ){
					console.group('OvImages • slideRatio')
					console.log('images:', images)
					console.log('firstImage:', firstImage)
					console.log('ratio:', ratio)
					console.groupEnd()
				}

				return ratio ? ratio : 0.5
			},
			currentText(){
				const currentIndex = this.currentIndex
				const images       = this.images
				const doLog        = false

				if( doLog ){
					console.group('OvImages • currentText')
					console.log('images:', images)
					console.groupEnd()
				}

				return images ? images[currentIndex].text : ''
			},
		},
		methods: {
			onSliderReady( e, payload, doLog = false ){
				const currentSlide      = payload.currentSlide ? payload.currentSlide : undefined
				const currentSlideIndex = currentSlide ? currentSlide.index           : undefined

				if( doLog ){
					console.group('OvImages • sliderReady()')
					console.log('e:', e)
					console.log('payload:', payload)
					console.log('currentSlide:', currentSlide)
					console.log('currentSlideIndex:', currentSlideIndex)
					console.groupEnd()
				}
			},
			onSliderSlide( e, payload, doLog = false ){
				const currentSlide      = payload.currentSlide ? payload.currentSlide : undefined
				const currentSlideIndex = currentSlide ? currentSlide.index           : undefined

				if( doLog ){
					console.group('OvImages • onSliderSlide()')
					console.log('e:', e)
					console.log('payload:', payload)
					console.log('currentSlide:', currentSlide)
					console.log('currentSlideIndex:', currentSlideIndex)
					console.groupEnd()
				}

				this.currentIndex = currentSlideIndex
			},
		},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvImages { // layout
		&__texts {
			.grid;
			.grid--setAreas;
			.grid--colGap;

			@media @mq[dt] {
				.grid--cols-12;
				grid-template-areas: ". . a b b b b b b b . .";
			}
			@media @mq[md] {
				.grid--cols-8;
				grid-template-areas: "a a b b b b b b";
			}
			@media @mq[sm] {
				//.grid--cols-8;
				//grid-template-areas: "a a a b b b b b";
			}

		}
	}
	.OvImages {
		.vueperSlide {
			position: relative;
		}
		.vueperSlides__arrows {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			display: flex;
			pointer-events: none;
		}
		.vueperSlides__arrow {
			position: absolute;
			top: 0; bottom: 0;
			display: flex;
			align-items: center;
			width: 8em;
			padding: 1em;
			justify-content: flex-end;
			pointer-events: auto;

			&--prev {
				//background-color: fade(blue, 20);
				left: 0;
				transform: rotate(180deg);
			}
			&--next {
				//background-color: fade(red, 20);
				right: 0;
			}
		}

		.OvImages__image {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		.OvArrowButton {
			height: 1.5em;
			width: 1.5em;
		}

		.OvImages__index {
			white-space: nowrap;

			&Slash {
				display: inline-block;
				background-color: currentColor;
				width: 3px;
				height: 1em;
				margin: 0 0.1em;
				transform: translateY(14%) rotate(18deg);
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
