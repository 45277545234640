<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvLogoGrid></OvLogoGrid>

	2020-11-23	init

-->

<template>
	<div class="OvLogoGrid contentModule" :class="elmClasses">
		<div class="OvLogoGrid__inner contentModule__inner hSpace hSpace--app maxWidth maxWidth--app">
			<div>
				<h3 class="OvLogoGrid__title font font--bold font--sizeMini vSpace vSpace--miniTitle miniTitleWithLine" v-if="title">
					{{title}}
				</h3>
				<div class="OvLogoGrid__items">
					<template v-for="(item, i) in items">
						<template v-if="item.link">
							<a class="OvLogoGrid__item link" :key="i" target="_blank" :href="item.link">
								<div class="OvLogoGrid__itemImageWrapper" v-if="item.logo">
									<MhImage class="OvLogoGrid__itemImage"
										:imageObject="item.logo"
										:mode="'contain'"
									></MhImage>
								</div>
								<div class="OvLogoGrid__itemTitle richText font font--bold font--sizeSmall">
									{{item.title}}
								</div>
							</a>
						</template>
						<template v-else>
							<div class="OvLogoGrid__item link" :key="i">
								<div class="OvLogoGrid__itemImageWrapper" v-if="item.logo" >
									<MhImage class="OvLogoGrid__itemImage"
										:imageObject="item.logo"
										:mode="'contain'"
									></MhImage>
								</div>
								<div class="OvLogoGrid__itemTitle richText font font--bold font--sizeSmall">
									{{item.title}}
								</div>
							</div>
						</template>
					</template>
				</div>
				<!--
				<pre name="items">{{items}}</pre>
				<pre name="title">{{title}}</pre>
				-->
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '@/components/MhImage/MhImage.vue'

	export default {
		name: 'OvLogoGrid',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			title: {
				type: [String, Boolean],
				default: '',
			},
			items: {
				type: [Array, Boolean],
				default: false,
			},
		},
		data() {
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			handleScroll(){
				console.log('scrolled')
			}
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	XX.OvLogoGrid { // debug
		&__item {
			outline: 1px solid red;
		}
		&__itemImageWrapper {
			background-color: fade( red, 20 );
		}
		&__itemImage {}
		&__itemTitle {
			background-color: fade( green, 20 );
		}
	}
	.OvLogoGrid { // grid layout
		&__inner {
			.grid;
			.grid--setAreas;
			.grid--colGap;
			.grid--cols-12;

			@media @mq[dt] {
				grid-template-areas: ". . a a a a a a a a . .";
			}
			@media @mq[md] {
				//grid-template-areas: ". . a a a a a a";
				grid-template-areas: "a a a a a a a a a a a a";
			}
			@media @mq[sm] {
				grid-template-areas: "a a a a a a a a a a a a";
			}
		}
		&__items {
			.grid;
			.grid--colGap;
			.grid--rowGap;

			@media @mq[dt] {
				.grid--cols-4;
			}
			@media @mq[md] {
				.grid--cols-3;
			}
		}
		&__item {
			display: flex;
			flex-direction: column;
		}
		&__itemImageWrapper {
			position: relative;
		}
		&__itemImage {
			position: absolute;
			top: 0; left: 0; bottom: 0; right: 0;
		}
		&__itemTitle {}
	}
	.OvLogoGrid { // styling
		&__inner {}

		&__title {
			//border-bottom: 3px solid currentColor;
		}

		&__items {
			//border-bottom: 3px solid currentColor;
		}

		&__item {}
		&__itemImageWrapper {
			width: 100%;
			padding-bottom: 75%;
			margin-bottom: 0.5em;
		}
		&__itemImage {}
		&__itemTitle {
			//flex-grow: 1;
			//align-items: flex-end;
			padding: 0.5em 0;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
