<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="OvAccordion contentModule" :class="elmClasses">
		<div class="OvAccordion__inner contentModule__inner layout layout--□□▨▨▨▨▨▨▨▨▨▨ hSpace hSpace--app maxWidth maxWidth--app" :class="elmInnerClasses">
			<div>
				<Accordion class="OvAccordion__accordionWrapper">
					<AccordionItem class="hover hover--listItem" :title="item.title" v-for="(item, index) in accordion" :key="index">
						<div class="richText font font--sizeDefault" v-html="item.text"></div>
					</AccordionItem>
				</Accordion>
			</div>
			<!--
			<pre data-name="accordion">{{accordion}}</pre>
			<div class="font richText lineCrop" :class="['font--size' + _.upperFirst(this.fontSize)]" v-html="text"></div>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'
	import Accordion from '@/components/MhAccordion/MhAccordion.vue'
	import AccordionItem from '@/components/MhAccordion/MhAccordionItem.vue'

	export default {
		name: 'OvAccordion',
		components: {
			Accordion,
			AccordionItem,
		},
		mixins: [],
		props: {
			accordion: {
				type: [Array, Boolean],
				default: false,
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			elmInnerClasses(){
				let classes = []

				return classes
			},
		},
		methods: {},
		created() {
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted() {},
		destroyed () {
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvAccordion {
		.Accordion {
			border-bottom: 3px solid currentColor;
		}
		.AccordionItem {
			overflow: hidden;
			padding: 0 0.25em;
			border-top: 3px solid currentColor;

			&__header {
				//background-color: fade( red, 20 );

				height: 1.75em;
				font-weight: 600;
			}
			&__headerTitle {
				padding-right: 0.5em;
			}
			&__headerToggleIconWrapper {
				transform: scale(0.85) translateY(-10%);
			}
			&__bodyContent {
				//outline: 1px solid red;
				padding: 0.5em 0 1.5em 0;
				//opacity: 0.7;

				@media @mq[dt] { width: 90%; }
				@media @mq[md] { width: 90%; }
				@media @mq[sm] { width: 100%; }
			}

			&--isActive {
				&:hover { background-color: transparent; } // disable hover--listItem styles
			}
			&--isInactive {}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
