<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvHtmlEmbeds></OvHtmlEmbeds>

	2020-11-23	init

-->

<template>
	<div class="OvHtmlEmbeds contentModule" :class="elmClasses">
		<div class="OvHtmlEmbeds__inner contentModule__inner maxWidth maxWidth--app hSpace hSpace--app">
			<div class="OvHtmlEmbeds__htmlEmbed" v-for="(htmlEmbed, index) in htmlEmbeds" :key="index">
				<MhConsentEmbedWrapper
					:htmlCode="htmlEmbed.htmlCode"
					:titleClass="'font font--bold'"
					:textClass="'font font--bold'"
				></MhConsentEmbedWrapper>
				<div class="OvHtmlEmbeds__htmlEmbedTextWrapper" v-if="htmlEmbed.text">
					<OvSpacer></OvSpacer>
					<div class="richText breakWord lineCrop font font--sizeSmall" v-html="htmlEmbed.text"></div>
					<OvSpacer></OvSpacer>
				</div>
			</div>
		</div>
		<!--
		<pre name="htmlEmbeds">{{htmlEmbeds}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import MhConsentEmbedWrapper from '@/components/MhConsent/MhConsentEmbedWrapper.vue'
	import OvSpacer from '@/components/OvSpacer.vue'

	export default {
		name: 'OvHtmlEmbeds',
		components: {
			MhConsentEmbedWrapper,
			OvSpacer,
		},
		mixins: [],
		props: {
			htmlEmbeds: {
				type: [Array, Boolean],
				default: false,
			},
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed() {},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvHtmlEmbeds { // layout
		&__inner {
			.grid;
			.grid--colGap;

			@media @mq[dt] {
				.grid--cols-2;
			}
			@media @mq[md] {
				.grid--cols-1;
			}
			@media @mq[sm] {}
		}
	}
	.OvHtmlEmbeds { // styling

	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
