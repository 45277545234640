<!--

	Diese Component gibt einen IFrame im richtigen Seitenverhältnis,
	definiert anhand der width + height attr des iframe-tags,
	mit 100% Breite aus.

	Welche $props gibt es?
		htmlCode = html string, e.g. '<iframe .... ></iframe>'

	Beispiel Code:
		<MhEmbed
			:htmlCode="'<iframe src=""></iframe>'"
			:doLoadIframe="true"
		></MhEmbed>

	2020-12-28	init

-->

<template>
	<div class="MhEmbed" :class="elmClasses" v-if="htmlCode">
		<div class="MhEmbed__inner">
			<div class="MhEmbed__embedWrapper" :style="{ paddingBottom }">
				<div class="MhEmbed__embedInner" v-html="htmlString" v-if="doLoadIframe"></div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhEmbed',
		components: {},
		mixins: [],
		props: {
			htmlCode: {
				type: [String, Boolean],
				default: '',
			},
			doLoadIframe: {
				type: [String, Boolean],
				default: true,
			},
		},
		data() {
			return {
				paddingBottom : null, // defines elm ratio
			}
		},
		watch: {
			htmlCode: {
				handler: function( to, from ) {
					this.extractIframeDimensions( to )
				},
				immediate : true,
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			htmlString(){
				let htmlCode   = this.htmlCode
				let htmlString = htmlCode

				/*
				let elm    = this.elm

				console.log('elm:', )
				console.log('elm:', elm)

				let width  = elm ? elm.getAttribute('width') : undefined
				let height = elm ? elm.getAttribute('height') : undefined

				console.group('OvHtmlEmbed • htmlString')
				console.log('htmlCode:', htmlCode)
				console.log('width:', width)
				console.log('height:', height)
				console.groupEnd()
				*/

				return htmlString
			},
		},
		methods: {
			extractIframeDimensions( htmlCode, doLog = false ) {
				const div  = document.createElement('div')
				let elm    = undefined
				let width  = undefined
				let height = undefined
				let ratio  = undefined

				if( htmlCode ){
					div.innerHTML = htmlCode.trim()

					elm    = div.querySelector('iframe')
					elm    = elm ? elm : undefined
					width  = elm ? elm.getAttribute('width') : undefined
					height = elm ? elm.getAttribute('height') : undefined

					ratio  = (width && height) ? ((height/width)*100) + '%' : undefined

					this.paddingBottom = ratio
				}

				if( doLog ){
					console.group('MhEmbed • extractIframeDimensions()')
					console.log('htmlCode:', htmlCode)
					console.log('elm:', elm)
					console.log('width:', width)
					console.log('height:', height)
					console.log('ratio:', ratio)
					//console.log('width:', width)
					//console.log('height:', height)
					console.groupEnd()
				}

				return elm
			}
		},
		created() {},
		mounted() {},
		destroyed () {},
	}
</script>

<style lang="less">
	.MhEmbed {
		position: relative;

		&__embedWrapper {
			position: relative;
		}
		&__embedInner {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background-color: fade( black, 10 );

			& > * { display: none; } // hide everything but iframes

			iframe {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				height: 100%; width: 100%;
				display: block;
			}
		}
	}
</style>
