<!-- 

	Was macht diese Componente?
	Wofür ist die Compoente da?
	
	Welche $props gibt es?
	
	Beispiel Code:
		<AccordionItem
			title="First AccordionItem Title"
		></AccordionItem>
	
	2020-11-24	improved: remove useless styling
	2020-06-08	init

-->

<template>
	<div class="AccordionItem" :class="elmClasses">
		<div class="AccordionItem__header" @click="toggle()">			
			<div class="AccordionItem__headerTitle">
				{{title}}
			</div>
			<div class="AccordionItem__headerToggleIconWrapper">
				<div class="AccordionItem__headerToggleIcon Icon--triangleDown"></div>
			</div>
		</div>
		<div class="AccordionItem__body" v-show-slide="isActive">
			<!--
			<div class="AccordionItem__bodyContent" v-html="content"></div>
			-->
			<div class="AccordionItem__bodyContent">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'AccordionItem',
		components: {},
		mixins: [],
		props: {
			title: [String, Boolean],
		},
		data() {
			return {
				isActive : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []
				
				if( this.isActive ){
					classes.push( this.$options.name + '--isActive')
				}else{
					classes.push( this.$options.name + '--isInactive')					
				}
				
				return classes
			},
			content(){
				let log = this._.get( this.$slots, 'default[0]')
				
				console.log('log:', log)
				
				return this._.get( this.$slots, 'default[0].text')
			},
		},
		methods: {
			toggle(){
				this.$parent.toggleItem( this )
			},
			setActive( isActive ){
				this.isActive = isActive
			},
		},
		created() {
			this.$parent.registerItem( this )
		},
		mounted() {
			//console.log(this.$slots);
			//console.log(this.$slots.default);
		},
	}
</script>

<style lang="less">
	.Icon--triangleDown {
		width: 0.75em; 
		height: 0.75em;
		display: inline-block;
		
		background-color: currentColor;
		clip-path: polygon( 50% 100%, 0 20%, 100% 20% );
	}
	
	.AccordionItem {
		position: relative;
		display: flex;
		flex-direction: column;
		transition: all 0.2s ease;
		overflow: hidden;
		
		&--isActive {
			//flex-grow: 1;
		}
	}
	.AccordionItem__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		user-select: none;
		cursor: pointer;
		
		&Title {
			overflow: hidden;
			white-space: pre;
			text-overflow: ellipsis;
		}
		&ToggleIconWrapper {}	
		&ToggleIcon {
			transition: all 0.2s ease;
			
			.AccordionItem--isActive & {
				transform: rotate( 180deg );
			}
		}
	}
	.AccordionItem__body {
		//.bg--grey;
		//.bg--lightGrey;
		
		position: relative;
		flex-grow: 1;
		height: 0;
		
		/*
		&::before {
			position: absolute;
			top: 0; 
			left: 0; right: 0; bottom: 0;
			//height: 50px;
			background: linear-gradient(
				180deg, 
				rgba(0,0,0,1) 100%,
				rgba(255,255,255,0) 0%
			);
			//mix-blend-mode: multiply;
			opacity: 0;
			//z-index: 1;
			content: "";
		}
		*/
		
		&Content {
			//.vhSpace--default;
			//.borderBottom--grey;
			
			/*
			position: absolute;
			top: 0; left: 0;
			right: 0; bottom: 0;
			*/
			overflow: auto;
		}
	}

	.AccordionItem--isInactive {
		.AccordionItem__body {}
	}
	.AccordionItem--isActive {
		.AccordionItem__body {}
	}
</style>
